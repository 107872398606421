<template>
  <div class="face-scanner-section">
    <div class="face-scanner-section__progress-bar face-scanner-progress-bar">
      <div class="face-scanner-progress-bar__title u-typography-helvetica u-text u-text--xs">
        {{ progressTitle }}
      </div>
      <progress-bar
        v-if="isProgressBarVisible"
        class="face-scanner-progress-bar__bar"
        :color="progressColor"
        :value="currentProgressValue"
        tiny
        semi-transparent-background
      />
    </div>

    <div class="face-scanner-wrap">
      <img
        class="face-scanner-wrap__circle-img face-scanner-wrap__circle-img--inner"
        src="@/modules/questionnaire/assets/images/face-scanner/circle.svg"
        alt=""
      />
      <img
        class="face-scanner-wrap__circle-img face-scanner-wrap__circle-img--outer"
        src="@/modules/questionnaire/assets/images/face-scanner/circle.svg"
        alt=""
      />

      <div class="face-scanner face-scanner-wrap__face-scanner" :style="photoStyles">
        <div class="face-scanner__dimmer"></div>
        <div class="face-scanner__gradient"></div>
        <div
          v-for="{ x, y } of $options.DOTS"
          :key="`${x}${y}`"
          class="face-scanner__dot"
          :style="getDotStyles(x, y)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/modules/questionnaire/components/common/ProgressBar';

import { isAppleDevice } from '@/utils/featureDetection';

/** Offset in percentages of container width */
const DOTS = [
  { x: 35, y: 25 },
  { x: 60, y: 30 },
  { x: 45, y: 45 },
  { x: 55, y: 60 },
  { x: 38, y: 62 },
  { x: 70, y: 70 },
  { x: 65, y: 84 }
];

const PROGRESS_STEP_DURATION = 1000 * 2.5;

export default {
  name: 'FaceScanner',
  components: { ProgressBar },
  DOTS,
  props: {
    photo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentProgressStep: 0,
      currentProgressValue: 0,
      lastProgressUpdateTime: performance.now()
    };
  },
  computed: {
    photoStyles() {
      return {
        '--background-image': `url(${this.photo})`
      };
    },
    progressBarElements() {
      return [
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.oxidativeStress')]),
          color: 'oxidative-stress'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.skinAppearance')]),
          color: 'skin-appearance'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.skinRedness')]),
          color: 'skin-redness'
        },
        {
          title: this.$t('scanningFacePage.introText', [
            this.$t('dysfunction.drynessAndDehydration')
          ]),
          color: 'skin-dryness'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.skinOilness')]),
          color: 'skin-oiliness'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.skinTexture')]),
          color: 'skin-texture'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.pimples')]),
          color: 'pimples'
        },
        {
          title: this.$t('scanningFacePage.introText', [this.$t('dysfunction.skinPigmentation')]),
          color: 'skin-pigmentation'
        }
      ];
    },
    currentProgressElement() {
      return this.progressBarElements[this.currentProgressStep];
    },
    progressTitle() {
      return this.currentProgressElement.title;
    },
    progressColor() {
      return this.currentProgressElement.color;
    },
    isProgressBarVisible() {
      // TODO: https://jira.andersenlab.com/browse/UNK-6591
      // the bar's animation is out of sync with scanner animation on all Apple devices
      // until it's not fixed, we hide the bar on Apple devices
      return !isAppleDevice();
    }
  },
  created() {
    requestAnimationFrame(this.animateProgress);
  },
  methods: {
    getDotStyles(x, y) {
      const delay = 1.8 + y / 100;

      return {
        '--dot-top': `${y}%`,
        '--dot-left': `${x}%`,
        '--dot-animation-delay': `${delay}s`
      };
    },
    animateProgress(currentTime) {
      const timeSinceLastUpdate = Math.abs(this.lastProgressUpdateTime - currentTime);

      if (timeSinceLastUpdate > PROGRESS_STEP_DURATION) {
        this.lastProgressUpdateTime = currentTime;
        this.moveCurrentProgressStep();
      }

      this.currentProgressValue = Math.round((timeSinceLastUpdate / PROGRESS_STEP_DURATION) * 100);

      requestAnimationFrame(this.animateProgress);
    },
    moveCurrentProgressStep() {
      this.currentProgressStep = (this.currentProgressStep + 1) % this.progressBarElements.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.face-scanner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  &__progress-bar {
    margin-top: 16px;
    margin-bottom: 70px;
    width: 100%;
  }
}

.face-scanner-progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 8px;
    text-align: center;
    color: var(--u-color-white);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__bar {
    width: 242px;
    max-width: 100%;
  }
}

.face-scanner-wrap {
  position: relative;

  &__circle-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    --circle-inner-scale-factor: 1.15;
    --circle-outer-scale-factor: 1.3;

    &--inner {
      transform: scale(var(--circle-inner-scale-factor));
      animation: inner-circle-rotation 45s infinite linear;
    }

    &--outer {
      transform: scale(var(--circle-outer-scale-factor));
      animation: outer-circle-rotation 35s infinite linear reverse;
    }
  }

  &__face-scanner {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.face-scanner {
  --animation-duration: 2.5s;
  --photo-size: 240px;

  /* A fix for the safari bug with rounded box overflow
   https://forum.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060 */
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--photo-size);
  height: var(--photo-size);
  background-color: grey;
  background-image: var(--background-image);
  background-position: center center;
  background-size: cover;
  position: relative;

  &__dimmer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--u-color-grey-900);
    opacity: 0.5;
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      rgba(236, 202, 25, 0) 0%,
      rgba(236, 202, 25, 0.1) 30%,
      rgba(236, 202, 25, 0.35) 60%,
      rgba(236, 202, 25, 0.8) 100%
    );
    transform: translateY(-100%);
    animation: scanner var(--animation-duration) infinite,
      scanner-color calc(var(--animation-duration) * 8) steps(8, end) infinite;
  }

  &__dot {
    position: absolute;

    background-color: white;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    top: var(--dot-top);
    left: var(--dot-left);

    opacity: 0;
    animation: dots var(--animation-duration) infinite;
    animation-delay: var(--dot-animation-delay);
  }
}

@keyframes scanner {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(var(--photo-size));
  }
}

@keyframes scanner-color {
  from {
    background: linear-gradient(
      rgba(236, 202, 25, 0) 0%,
      rgba(236, 202, 25, 0.1) 30%,
      rgba(236, 202, 25, 0.35) 60%,
      rgba(236, 202, 25, 0.8) 100%
    );
  }

  12.5% {
    background: linear-gradient(
      rgba(231, 148, 138, 0) 0%,
      rgba(231, 148, 138, 0.1) 30%,
      rgba(231, 148, 138, 0.35) 60%,
      rgba(231, 148, 138, 0.8) 100%
    );
  }

  24% {
    background: linear-gradient(
      rgba(238, 69, 51, 0) 0%,
      rgba(238, 69, 51, 0.1) 30%,
      rgba(238, 69, 51, 0.35) 60%,
      rgba(238, 69, 51, 0.8) 100%
    );
  }

  37.5% {
    background: linear-gradient(
      rgba(116, 159, 194, 0) 0%,
      rgba(116, 159, 194, 0.1) 30%,
      rgba(116, 159, 194, 0.35) 60%,
      rgba(116, 159, 194, 0.8) 100%
    );
  }

  50% {
    background: linear-gradient(
      rgba(214, 101, 65, 0) 0%,
      rgba(214, 101, 65, 0.1) 30%,
      rgba(214, 101, 65, 0.35) 60%,
      rgba(214, 101, 65, 0.8) 100%
    );
  }

  62.5% {
    background: linear-gradient(
      rgba(42, 37, 41, 0) 0%,
      rgba(42, 37, 41, 0.1) 30%,
      rgba(42, 37, 41, 0.35) 60%,
      rgba(42, 37, 41, 0.8) 100%
    );
  }

  75% {
    background: linear-gradient(
      rgba(21, 88, 98, 0) 0%,
      rgba(21, 88, 98, 0.1) 30%,
      rgba(21, 88, 98, 0.35) 60%,
      rgba(21, 88, 98, 0.8) 100%
    );
  }

  87.5% {
    background: linear-gradient(
      rgba(93, 26, 19, 0) 0%,
      rgba(93, 26, 19, 0.1) 30%,
      rgba(93, 26, 19, 0.35) 60%,
      rgba(93, 26, 19, 0.8) 100%
    );
  }

  to {
    background: linear-gradient(
      rgba(236, 202, 25, 0) 0%,
      rgba(236, 202, 25, 0.1) 30%,
      rgba(236, 202, 25, 0.35) 60%,
      rgba(236, 202, 25, 0.8) 100%
    );
  }
}

@keyframes dots {
  from {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
}

@keyframes inner-circle-rotation {
  from {
    transform: scale(var(--circle-inner-scale-factor)) rotate(0deg);
  }

  to {
    transform: scale(var(--circle-inner-scale-factor)) rotate(360deg);
  }
}

@keyframes outer-circle-rotation {
  from {
    transform: scale(var(--circle-outer-scale-factor)) rotate(0deg);
  }

  to {
    transform: scale(var(--circle-outer-scale-factor)) rotate(360deg);
  }
}

@media (min-width: $tablet-start) {
  .face-scanner {
    --photo-size: 356px;
  }

  .face-scanner-section {
    &__progress-bar {
      margin-top: 8px;
    }
  }
}
</style>
