<template>
  <questionnaire-page-template
    :language="locale"
    :language-options="languageOptions"
    :loading="loading"
    :doctor-info="doctorInfo"
    :user-info="userInfo"
    dark
    no-footer
    no-divider
    content-centered
    no-time-tracker
    basic-background
    :title="$t('scanningFacePage.title')"
    @change-language="$emit('change-language', $event)"
  >
    <face-scanner :photo="photo" />
  </questionnaire-page-template>
</template>

<script>
import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';
import FaceScanner from '@/modules/questionnaire/components/face-scanner/FaceScanner';

export default {
  name: 'FaceScannerPageTemplate',
  components: { QuestionnairePageTemplate, FaceScanner },
  props: {
    photo: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    }
  }
};
</script>
