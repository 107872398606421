<template>
  <face-scanner-page-template
    :locale="locale"
    :language-options="languageOptions"
    :user-info="patientInfo"
    :doctor-info="doctorInfo"
    :photo="patientPhoto"
    @change-language="onChangeLanguage"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import FaceScannerPageTemplate from '@/modules/questionnaire/pages/face-scanner/FaceScannerPageTemplate';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import { types as questTypes } from '@/modules/questionnaire/store/types';

export default {
  name: 'PageFaceScanner',
  components: { FaceScannerPageTemplate },
  mixins: [changeLanguageMixin, doctorAndPatientInfoMixin],
  computed: {
    ...mapState({
      patientPhoto: state => state.questionnaire.patientPhoto
    })
  },
  async mounted() {
    if (!this.patientPhoto) {
      await this.fetchPatientQuestionnairePhoto();
    }

    await this.loadPhotoAnalysisResults();
    await this.redirectToQuestionnaire();
  },
  methods: {
    ...mapActions({
      loadPhotoAnalysisResults: questTypes.actions.LOAD_PHOTO_ANALYSIS_RESULTS,
      fetchPatientQuestionnairePhoto: questTypes.actions.FETCH_PATIENT_QUESTIONNAIRE_PHOTO
    }),
    async redirectToQuestionnaire() {
      await this.$router.replace({ name: 'Questions' });
    }
  }
};
</script>
